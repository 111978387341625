<div id="requestContainer" class="container" *ngIf="energyConsult">
  <div class="row">
    <div id="actionsContainer" class="col-md">
      <span>
        <button mat-flat-button [routerLink]="['../../']">
          <mat-icon>arrow_back_ios_new</mat-icon>
          <span>{{ "PAGES.BACK" | translate }}</span>
        </button>
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <div>
        <app-energy-consult [energyConsult]="energyConsult" [actions]="2" [inspecting]="true"></app-energy-consult>
      </div>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-8 align-self-center">
      <div class="row" *ngIf="showActions">
        <div id="alertContainer" class="col-md" *ngIf="hasAlert">
          <span class="alert" *ngIf="energyConsult.state.name === 'PendingReject'">
            <h5>{{ "COMPONENTS.COACH_REQUEST.ALERT.PENDING_REJECTED.TITLE" | translate }}</h5>
            <div *ngIf="energyConsult.coach && energyConsult.rejectReason; else noRejectReason">
              <p class="my-1">{{ "COMPONENTS.COACH_REQUEST.ALERT.PENDING_REJECTED.DESCRIPTION_WITH_REASON" | translate : { coachName: energyConsult.coach.fullName } }}:</p>
              <p class="text-muted fst-italic my-1">{{ energyConsult.rejectReason }}</p>
            </div>

            <ng-template #noRejectReason>
              <p>
                {{
                  "COMPONENTS.COACH_REQUEST.ALERT.PENDING_REJECTED.DESCRIPTION_WITHOUT_REASON" + (energyConsult.coach === undefined ? "_WITHOUT_COACH" : "")
                    | translate : { coachName: energyConsult.coach?.fullName ?? "" }
                }}
              </p>
            </ng-template>

            <div *appIsRole="'coordinator'" class="mt-3 d-flex gap-3 flex-wrap">
              <button mat-flat-button color="primary" (click)="dialog.show(recoverRequestDialog)">{{ "COMPONENTS.COACH_REQUEST.ALERT.RENEW_BUTTON" | translate }}</button>
              <button mat-flat-button color="warn" (click)="dialog.show(deleteRequestDialog)">{{ "FORMS.DELETE" | translate }}</button>
            </div>
          </span>

          <span class="alert" *ngIf="energyConsult.state.name === 'Disapproved'">
            <h5>{{ "COMPONENTS.COACH_REQUEST.ALERT.DISAPPROVED.TITLE" | translate }}</h5>
            <p>
              {{
                "COMPONENTS.COACH_REQUEST.ALERT.DISAPPROVED.DESCRIPTION"
                  | translate : { residentName: energyConsult.resident?.firstName + " " + energyConsult.resident?.lastName }
              }}:
            </p>
            <p class="text-muted fst-italic my-1">{{ energyConsult.disapprovalReason }}</p>
          </span>
        </div>
      </div>

      <div class="row">
        <!-- COORDINATOR -->
        <ng-container *appIsRole="'coordinator'">
          <div class="editContainer col-md">
            <div class="row">
              <div [ngSwitch]="energyConsult.state.name">
                <div *ngSwitchCase="'Disapproved'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'PendingDeleted'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'Canceled'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'PendingReject'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'New'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="changeCoach"></ng-container>
                    <ng-container *ngTemplateOutlet="ckdTemplate"></ng-container>
                    <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'Picked'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="changeCoach"></ng-container>
                    <ng-container *ngTemplateOutlet="scheduleVisit"></ng-container>
                  </div>
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="rejectAndPutback"></ng-container>
                    <ng-container *ngTemplateOutlet="editConsultData"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>

                <div *ngSwitchCase="'Date'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="changeCoach"></ng-container>
                    <ng-container *ngTemplateOutlet="scheduleVisit"></ng-container>
                  </div>
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="rejectAndPutback"></ng-container>
                    <ng-container *ngTemplateOutlet="editConsultData"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>

                <div *ngSwitchCase="'FilledIn'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="downloadReport"></ng-container>
                  </div>

                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="fileUpload"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>

                <div *ngSwitchCase="'Done'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="downloadReport"></ng-container>
                  </div>

                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="fileUpload"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- COACH -->
        <ng-container *appIsRole="'coach'">
          <div class="editContainer col-md" *ngIf="!energyConsult.canBeClaimed; else claimTemplate">
            <div class="row">
              <div [ngSwitch]="energyConsult.state.name">
                <div *ngSwitchCase="'Canceled'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'PendingReject'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'Picked'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="scheduleVisit"></ng-container>
                  </div>

                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="rejectAndPutback"></ng-container>
                    <ng-container *ngTemplateOutlet="editConsultData"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>

                <div *ngSwitchCase="'Date'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="scheduleVisit"></ng-container>
                    <ng-container *ngTemplateOutlet="fillReport"></ng-container>
                  </div>

                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="rejectAndPutback"></ng-container>
                    <ng-container *ngTemplateOutlet="editConsultData"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>

                <div *ngSwitchCase="'FilledIn'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="downloadReport"></ng-container>
                    <ng-container *ngTemplateOutlet="replacementClaim"></ng-container>
                  </div>

                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="fileUpload"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>

                <div *ngSwitchCase="'Done'" class="row">
                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="downloadReport"></ng-container>
                    <ng-container *ngTemplateOutlet="replacementClaim"></ng-container>
                  </div>

                  <div class="col-md">
                    <ng-container *ngTemplateOutlet="fileUpload"></ng-container>
                    <ng-container *ngTemplateOutlet="mailDataTimeline"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Accept request -->
        <ng-template #claimTemplate>
          <div class="editContainer row">
            <div class="col-md action">
              <div class="row mb-1">
                <div class="col-md d-flex gap-3">
                  <mat-icon>done_all</mat-icon>
                  <label>{{ "COMPONENTS.COACH_REQUEST.CLAIM.DESCRIPTION" | translate }}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <button mat-flat-button class="btn-block primary my-1" (click)="claimEnergyConsult()">
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ "COMPONENTS.COACH_REQUEST.CLAIM.ACCEPT_BUTTON" | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="notesTemplate"></ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!-- ckd template -->
<ng-template #ckdTemplate>
  <div class="col-md-6" *appModule="'CKD_SUBF'">
    <div class="row">
      <div class="class-md action">
        <div class="row mb-1">
          <div class="col-md d-flex gap-3">
            <mat-icon>move_up</mat-icon>
            <label>{{ "COMPONENTS.COACH_REQUEST.CKD.ACTION_MESSAGE" | translate }}</label>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-md d-flex gap-3">
            <button mat-flat-button class="primary" (click)="ckdEnergyConsult()">{{ "COMPONENTS.COACH_REQUEST.CKD.ACTION_BUTTON" | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- notes template -->
<ng-template #notesTemplate>
  <div class="col-md-12" *appModule="'NOTE'">
    <div class="row">
      <div class="col-md action">
        <div class="row mb-1">
          <div class="col-md d-flex gap-3">
            <mat-icon>speaker_notes</mat-icon>
            <label>{{ "COMPONENTS.COACH_REQUEST.NOTE.ROLE_NOTES" | translate }}</label>
          </div>
        </div>
        <div class="card mt-1 md10 my-2">
          <div class="card-body">
            <textarea
              matInput
              [formControl]="noteControl"
              id="addANote"
              class="form-control"
              cdkTextareaAutosize
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="10"
              placeholder="{{ 'COMPONENTS.COACH_REQUEST.NOTE.NOTE_HINT' | translate }}"
            ></textarea>
            <div class="d-flex justify-content-between">
              <button #changeNoteButton mat-flat-button class="btn-block primary my-1 mt-1" mat-raised-button (click)="changeNote(changeNoteButton)" disabled="false">
                <mat-icon class="text-light">save</mat-icon>{{ "COMPONENTS.COACH_REQUEST.NOTE.SAVE_NOTE_BUTTON" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pdfGenerateSuccess>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.PDF.SUCCESS_MESSAGE" | translate }}</h3>
        <br />
        <img src="https://c.tenor.com/q-zZSTX6jSIAAAAC/mail-download.gif" alt="Mail" /><br />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reject>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.DESCRIPTION" | translate }}</p>
        <mat-form-field class="example-full-width w-100" appearance="fill">
          <mat-label>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.REASON.NAME" | translate }}</mat-label>
          <textarea
            matInput
            placeholder="{{ 'COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.REASON.PLACEHOLDER' | translate }}"
            [formControl]="rejectReasonControl"
          ></textarea>
          <app-error [control]="rejectReasonControl"></app-error>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="warn" [disabled]="!rejectReasonControl.valid" (click)="rejectEnergyConsult()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #recoverRequestDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md touchScreenAccessability">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_RENEWAL.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_RENEWAL.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="recoverEnergyConsult(); dialog.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteRequestDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md touchScreenAccessability">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.DELETION.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.DELETION.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="warn" (click)="deleteEnergyConsult(); dialog.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #changeCoachDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md touchScreenAccessability">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.DESCRIPTION" | translate }}</p>

        <div class="d-block">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.LABEL" | translate }}</mat-label>
            <input
              type="text"
              #val
              placeholder="{{ 'COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.PLACEHOLDER' | translate }}"
              matInput
              [formControl]="changeCoachControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCoachName" #coachInput>
              <mat-option class="touchScreenAccessability" *ngFor="let coach of filteredOptions | async" [value]="coach">
                {{ coach.firstName }} {{ coach.lastName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="val.value.length === 0" (click)="assignCoach(changeCoachControl.value)">
          {{ "COMPONENTS.DIALOG.SUBMIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_EDIT.TITLE" | translate }}</h3>
        <form [formGroup]="editFormGroup">
          <div class="row field">
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.FIRST_NAME" | translate }}</mat-label>
                <input matInput formControlName="firstName" />
                <app-error [control]="editFormGroup.controls.firstName"></app-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row field">
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.LAST_NAME" | translate }}</mat-label>
                <input matInput formControlName="lastName" />
                <app-error [control]="editFormGroup.controls.lastName"></app-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row field">
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.EMAIL" | translate }}</mat-label>
                <input matInput formControlName="email" />
                <app-error [control]="editFormGroup.controls.email"></app-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row field" *appExcludeModule="'PHONE_NUMBER_REQUIRED'">
            <div class="col-md">
              <mat-checkbox class="mb-3" color="primary" formControlName="usePhoneNumber">{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.USE_PHONE_NUMBER" | translate }}</mat-checkbox>
            </div>
          </div>
          <div class="row field" *ngIf="editFormGroup.controls.usePhoneNumber.value">
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100" hideRequiredMarker>
                <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.PHONE_NUMBER" | translate }}</mat-label>
                <input matInput formControlName="phoneNumber" />
                <app-error [control]="editFormGroup.controls.phoneNumber"></app-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row field" formGroupName="address">
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.POSTAL_CODE" | translate }}</mat-label>
                <input matInput formControlName="postalCode" />
                <app-error [control]="editFormGroup.controls.address.controls.postalCode"></app-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row field" formGroupName="address">
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.HOUSE_NUMBER" | translate }}</mat-label>
                <input matInput formControlName="houseNumber" />
                <app-error [control]="editFormGroup.controls.address.controls.houseNumber"></app-error>
              </mat-form-field>
            </div>
            <!-- </div>
          <div class="row field"> -->
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.HOUSE_NUMBER_SUFFIX" | translate }}</mat-label>
                <input matInput formControlName="houseNumberSuffix" />
                <app-error [control]="editFormGroup.controls.address.controls.houseNumberSuffix"></app-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row field">
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.STREETNAME" | translate }}</mat-label>
                <input matInput formControlName="streetName" />
                <app-error [control]="editFormGroup.controls.streetName"></app-error>
              </mat-form-field>
            </div>
            <!-- </div>
          <div class="row field"> -->
            <div class="col-md">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.TOWN" | translate }}</mat-label>
                <input matInput formControlName="town" />
                <app-error [control]="editFormGroup.controls.town"></app-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div class="d-flex gap-3 flex-wrap justify-content-center">
          <button mat-raised-button color="" class="m-0" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</button>
          <button mat-raised-button color="primary" class="m-0" (click)="saveEditedRequest(); dialog.close()" [disabled]="editFormGroup.invalid">
            {{ "COMPONENTS.DIALOG.SUBMIT" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- change coach -->
<ng-template #changeCoach>
  <div class="col-md editContainer" *ngIf="energyConsult?.canCoachBeChanged">
    <div class="row">
      <div class="col-md action">
        <div class="row mb-1">
          <div class="col-md d-flex gap-3">
            <mat-icon>hail</mat-icon>
            <label class="d-block">{{ "COMPONENTS.COACH_REQUEST.CHANGE_COACH.INFO" | translate }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md">
            <button mat-flat-button class="btn-block primary my-1" (click)="this.changeCoachClickHandler(changeCoachDialog)">
              <span>{{ "COMPONENTS.COACH_REQUEST.CHANGE_COACH.NAME" | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #scheduleVisit>
  <div class="col-md-12" *ngIf="energyConsult?.canBeChanged">
    <div class="row">
      <div class="col-md action">
        <div class="row mb-1">
          <div class="col-md d-flex gap-3">
            <mat-icon>work_history</mat-icon>
            <label class="d-block">{{
              energyConsult?.appointmentDate ? ("COMPONENTS.TILE.DATE_PLANNED" | translate) : ("COMPONENTS.TILE.DATE_NOT_PLANNED" | translate)
            }}</label>
          </div>

          <div class="row my-1">
            <span class="text-muted fst-italic">{{ energyConsult?.appointmentDate ? (energyConsult!.appointmentDate! | localDate : "EEEE, d MMMM y, HH:mm") : "" }}</span>
          </div>
        </div>

        <div class="row" *ngIf="isEditingDate">
          <div class="col-md">
            <!-- list of material cards showing time slots -->
            <p *ngIf="energyConsult?.extraProperties?.preferredTimeSlots ? (energyConsult?.extraProperties?.preferredTimeSlots)!.length > 0 : false">
              {{ "COMPONENTS.COACH_REQUEST.RESIDENT_PREFERRED_APPOINTMENT" | translate }}
            </p>
            <div style="display: flex; flex-direction: row; overflow-x: auto; gap: 0.25rem; margin-bottom: 0.5rem">
              <mat-card *ngFor="let timeSlot of energyConsult?.extraProperties?.preferredTimeSlots; index as slotIndex" style="margin: 0.25rem">
                <mat-card-content>
                  <div style="display: flex; flex-direction: row; min-width: max-content">
                    <div style="display: flex; flex-direction: column; justify-content: space-between; margin-right: 0.5rem">
                      <div>{{ timeSlot.startTime | localDate : "fullDate" }}</div>
                      <div>
                        {{
                          ("GENERAL.FROM" | translate) +
                            " " +
                            timeSlot.startTime.toLocaleTimeString("en-GB").slice(0, 5) +
                            " " +
                            ("GENERAL.UNTILL" | translate) +
                            " " +
                            timeSlot.endTime.toLocaleTimeString("en-GB").slice(0, 5)
                        }}
                      </div>
                    </div>
                    <button mat-icon-button (click)="fillInPreferredTimeSlot(slotIndex)" class="d-flex align-items-center">
                      <mat-icon style="color: #666">arrow_right_alt</mat-icon>
                    </button>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <form [formGroup]="formGroup">
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-75 mt-2">
                    <mat-label>{{ "COMPONENTS.COACH_REQUEST.DATE" | translate }}</mat-label>
                    <input matInput [min]="energyConsult?.minimalAppointmentDate" [matDatepicker]="datepicker" readonly [max]="maxDate" formControlName="date" />
                    <app-error [control]="formGroup.controls.date"></app-error>
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <br />
              </div>
              <div class="row">
                <div class="col-12">
                  <app-custom-timepicker #time givenFormControlName="time" [givenFormGroup]="formGroup"></app-custom-timepicker>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-md" *ngIf="!isEditingDate; else editDate">
            <button mat-flat-button class="btn-block primary my-1" (click)="setEditingDate(true)">
              <span>{{ "COMPONENTS.COACH_REQUEST.EDIT_DATE_TIME_BUTTON" | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #editDate>
      <div class="col-md">
        <button mat-flat-button class="btn-block primary" [disabled]="formGroup.invalid" (click)="saveDate()">
          <span>{{ "FORMS.SAVE" | translate }}</span>
        </button>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #rejectAndPutback>
  <div class="col-md" *ngIf="energyConsult?.canBeRejected">
    <div class="row">
      <div class="col-md action">
        <div class="row mb-1">
          <div class="col-md d-flex gap-3">
            <mat-icon>cancel</mat-icon>
            <label class="d-block">{{ "COMPONENTS.COACH_REQUEST.REJECT.INFO" | translate }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md">
            <button mat-flat-button class="btn-block primary m-1" (click)="dialog.show(reject)">
              <span [matTooltip]="'COMPONENTS.COACH_REQUEST.REJECT.DESCRIPTION' | translate">{{ "COMPONENTS.COACH_REQUEST.REJECT.NAME" | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- edit consult data -->
<ng-template #editConsultData>
  <ng-container *appModule="'EDIT_ENERGY_CONSULT'">
    <div class="col-md editContainer" *ngIf="canEditConsult()">
      <div class="row">
        <div class="col-md action">
          <div class="row mb-1">
            <div class="col-md d-flex gap-3">
              <mat-icon>edit</mat-icon>
              <label class="d-block">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_EDIT.TITLE" | translate }}</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md">
              <button mat-flat-button class="btn-block primary m-1" (click)="editRequestDialog()">
                <span [matTooltip]="'COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_EDIT.EDIT_TOOLTIP' | translate">{{
                  "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_EDIT.EDIT_TOOLTIP" | translate
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Fill in Report -->
<ng-template #fillReport>
  <div class="col-md-12 col-lg-6">
    <div class="row">
      <div class="col-md action">
        <div class="row mb-1">
          <div class="col-md d-flex gap-3">
            <mat-icon>done_all</mat-icon>
            <label class="d-block">{{ "COMPONENTS.COACH_REQUEST.FILL_IN_REPORT" | translate }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md">
            <div class="row">
              <div class="col-md">
                <button class="btn-block primary" mat-flat-button routerLink="report" [matTooltip]="'COMPONENTS.COACH_REQUEST.FILL_IN_REPORT_DESCRIPTION' | translate">
                  {{ "COMPONENTS.COACH_REQUEST.FILL_IN_REPORT" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Download report -->
<ng-template #downloadReport>
  <div class="col-md-12 editContainer">
    <div class="row">
      <div class="col-md action">
        <div class="row mb-1">
          <div class="col-md align-items-center d-flex gap-3">
            <mat-icon>picture_as_pdf</mat-icon>
            <label>{{ "COMPONENTS.COACH_REQUEST.DONE.DESCRIPTION" | translate }}</label>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md gap-3">
            <button mat-flat-button class="btn-block primary my-1" (click)="downloadReportAsPDF()">
              {{ "COMPONENTS.DIALOG.DOWNLOAD_PDF" | translate }} <mat-icon [ngStyle]="{ color: 'white' }">download</mat-icon>
            </button>
            <ng-container *appModule="'COACH_FINISH_REQUEST'">
              <ng-container *ngIf="energyConsult?.state?.name === 'FilledIn'">
                <button mat-flat-button color="primary" class="btn-block primary my-1" (click)="finishEnergyConsult()">
                  <mat-icon style="color: white">check</mat-icon>
                  <span>{{ "COMPONENTS.COACH_REQUEST.FINISH.BUTTON" | translate }}</span>
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col-md">
            <div class="w-100">
              <span>{{ "COMPONENTS.COACH_REQUEST.DONE.SEND_REPORT_BUTTON_LABEL" | translate }}</span>
            </div>
            <button mat-flat-button class="btn-block primary my-1" (click)="sendPDFMail()">
              <mat-icon style="color: white">mail</mat-icon>
              <span>{{ "COMPONENTS.COACH_REQUEST.DONE.SEND_REPORT_BUTTON" | translate }}</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md text-muted fst-italic" *ngIf="sendDateAvailable">
            {{ "COMPONENTS.COACH_REQUEST.DONE.REPORT_WAS_SEND" | translate }}
          </div>
        </div>
        <div class="row">
          <div class="col-md text-muted fst-italic">
            {{ sendDateAvailable && pdfSendDate ? (pdfSendDate | localDate : "EEEE, d MMMM y, HH:mm") : ("COMPONENTS.COACH_REQUEST.DONE.NOT_SEND_YET" | translate) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Replacement Claim -->
<ng-template #replacementClaim>
  <ng-container *appModule="'REPLACEMENT_CLAIMS'">
    <div class="col-md-12 editContainer">
      <div class="row">
        <div class="col-md action">
          <div class="d-flex align-items-center gap-3 mb-1">
            <mat-icon>swap_horiz</mat-icon>
            <label>{{ "COMPONENTS.COACH_REQUEST.DONE.REQUEST_REPLACEMENT" | translate }}</label>
          </div>
          <a mat-flat-button color="primary" class="btn-block primary my-1" [routerLink]="['/content/coach/replacement-claim/new', energyConsult?.id]">
            <span>{{ "COMPONENTS.COACH_REQUEST.DONE.REPLACEMENT_BUTTON" | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #mailDataTimeline>
  <ng-container *appModule="'MAIL_TIMELINE'">
    <div class="row">
      <div class="col-md action">
        <div class="d-flex align-items-center mb-1 gap-3">
          <mat-icon class="pe-4">mark_as_unread</mat-icon>
          <span>{{ "COMPONENTS.COACH_REQUEST.MAIL_TIMELINE.ACTION_TITLE" | translate }}</span>
        </div>
        <app-mail-data-timeline [energyConsult]="energyConsult"></app-mail-data-timeline>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- File upload -->
<ng-template #fileUpload>
  <div class="col-md-12 editContainer">
    <div class="row">
      <div class="col-md action">
        <div class="d-flex align-items-center mb-1 gap-3">
          <mat-icon class="pe-4">file_open</mat-icon>
          <span>{{ "TABLES.FILES.INFO" | translate }}</span>
        </div>
        <div class="mt-1">
          <div>
            <button type="button" class="btn-block primary my-1 me-1" mat-flat-button (click)="attachmentFileInput.click()">
              <mat-icon style="color: white">drive_folder_upload</mat-icon> {{ "COMPONENTS.COACH_REQUEST.CHOOSE_FILE" | translate }}
            </button>
            <input
              accept="image/*, application/pdf"
              multiple
              #attachmentFileInput
              name="attachmentFileInput"
              id="attachmentFileInput"
              type="file"
              style="display: none"
              (change)="newFileSelected()"
            />
            <label for="attachmentFileInput">{{ (fileSelected$ | async) ?? ("TABLES.FILES.UPLOAD" | translate) }}</label>
          </div>
          <button mat-flat-button class="btn-block primary my-1" (click)="uploadAttachment(attachmentFileInput.files)">
            {{ "COMPONENTS.COACH_REQUEST.UPLOAD" | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="fileNames.length" style="overflow: auto">
        <table style="overflow: scroll" mat-table [dataSource]="fileNames">
          <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef>{{ "TABLES.FILES.FILENAME" | translate }}</th>
            <td mat-cell *matCellDef="let file">{{ file.fileName }}</td>
          </ng-container>
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>{{ "TABLES.FILES.DOWNLOAD" | translate }}</th>
            <td mat-cell *matCellDef="let file">
              <button mat-icon-button (click)="downloadTableFile(file.getId, file.fileName)">
                <mat-icon style="color: #666">download</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>{{ "TABLES.FILES.DELETE" | translate }}</th>
            <td mat-cell *matCellDef="let file">
              <button mat-icon-button (click)="deleteTableFile(file.deleteId)">
                <mat-icon style="color: red">delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="fileSize">
            <th mat-header-cell *matHeaderCellDef>{{ "TABLES.FILES.FILESIZE" | translate }}</th>
            <td mat-cell *matCellDef="let file">
              <p class="m-0">{{ formatSize(file.fileSize) }}</p>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['fileName', 'fileSize', 'download', 'delete']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['fileName', 'fileSize', 'download', 'delete']"></tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ckdSuccesDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CKD.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CKD.MESSAGE" | translate : { newID: data.newID } }}</p>

        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>
